import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import ElementUI from 'element-ui';
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
import '@assets/css/iconfont/iconfont.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/bootstrap';
import '@/permission';

// 引入主题色
if (process.env.VUE_APP_VERSION === 'shwj') {
  // 同军事版样式
  require("@/themes/shwj");
} else if (process.env.VUE_APP_VERSION === 'military') {
  // 军事版样式
  require("@/themes/military");
} else if (process.env.VUE_APP_VERSION === 'yuantong') {
  // 圆通版样式
  require("@/themes/yuantong");
} else {
  require("@/themes/common");
}

// 开发环境使用 mock数据
if (process.env.VUE_APP_USE_MODEL === 'mock') {
  require("@/mock/index");
}

// 在这里设置全局默认配置
Vue.config.productionTip = false
Vue.use(vuescroll, {
  ops: {
    bar: {
      background: "#EBEBEB"
    }
  } // 在这里设置全局默认配置
});

Vue.use(ElementUI, {
  size: "small"
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');