import store from '@/store'
// import router from './router'
import axios from 'axios'

window.$axios = axios.create();
window.$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.$axios.defaults.baseURL = process.env.VUE_APP_API
window.$axios.defaults.timeout = 6000000   // 请求超时时间
window.$moment = require('moment');
window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
window.service = {
  api: process.env.VUE_APP_API,
  domain: process.env.VUE_APP_DOMAIN,
  caution: {open: process.env.VUE_APP_OPEN_CAUTION, interval: 10000}
};
// window.d3 = require('@/d3.js');
// window.d3 = require("d3");

import { getAuthToken, localDbClear } from "@/utils/helpers";

// 添加请求拦截器
window.$axios.interceptors.request.use(
  config => {
    config.headers['platform'] = 'web'
    const resetToken = store.state.config.reset_token; // 重置密码token
    const token = resetToken ? resetToken : getAuthToken();
    if (token) {
      config.headers['token'] = token; // 让每个请求携带认证token
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });

// 添加响应拦截器
window.$axios.interceptors.response.use(
  response => {
    if ([8, 9, 10].indexOf(response.data.code) !== -1) {
      // 需重新登陆：code 9 为token过期 , code 8 为token失效, code 10 为异端登录；
      localDbClear()
      window.location.href = `/login?code=${response.data.code}`

      return {data: {code: 0, data: [], state: false}}
    }

    // 对响应数据做点什么
    return response;
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  });

window.provinceGeoCoord = {
  // 23省
  '甘肃':[103.73, 36.03],
  '青海':[101.74, 36.56],
  '四川':[104.06, 30.67],
  '河北':[114.48, 38.03],
  '云南':[102.73, 25.04],
  '贵州':[106.71, 26.57],
  '湖北':[114.31, 30.52],
  '河南':[113.65, 34.76],
  '山东':[117, 36.65],
  '江苏':[118.78, 32.04],
  '安徽':[117.27, 31.86],
  '浙江':[120.19, 30.26],
  '江西':[115.89, 28.68],
  '福建':[119.3, 26.08],
  '广东':[113.23, 23.16],
  '湖南':[113, 28.21],
  '海南':[110.35, 20.02],
  '辽宁':[123.38, 41.8],
  '吉林':[125.35, 43.88],
  '黑龙江':[126.63, 45.75],
  '山西':[112.53, 37.87],
  '陕西':[108.95, 34.27],
  '台湾':[121.30, 25.03],
  // 4直辖市
  '北京':[116.46, 39.92],
  '上海':[121.48, 31.22],
  '重庆':[106.54, 29.59],
  '天津':[117.2, 39.13],
  // 5自治区
  '内蒙古':[111.65, 40.82],
  '广西':[108.33, 22.84],
  '西藏':[91.11, 29.97],
  '宁夏':[106.27, 38.47],
  '新疆':[87.68, 43.77],
  // 2特别行政区
  '香港':[114.17, 22.28],
  '澳门':[113.54, 22.19]
}

// 地图坐标
window.geoCoordMap = {
  "美国": [-95.712891, 37.09024],
  "日本": [139.691706,35.689487],
  "韩国": [127.766922, 35.907757],
  "朝鲜": [127.510093, 40.339852],
  "俄罗斯": [105.318756, 61.52401],
  "印度": [79.105459,21.185871],
  "印度尼西亚": [113.921327, -0.789275],
  "菲律宾": [121.774017, 12.879721],
  "越南": [108.277199, 14.058324],
  "缅甸": [95.956223, 21.913965],
  "泰国": [100.992541, 15.870032],
  "台湾": [121.56534, 25.033191],
  "中国": [116.409934,39.914467]
};

