import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
// 主页面视图
const Index = () => import('@components/index.vue');

// 登录页
const Login = () => import('@components/login/login-switch.vue');

// 舆情信息
const Yq = () => import('@components/yq/index.vue');
const PlanOverview = () => import('@components/yq/tabs/overview.vue');

// 方案
const EditPlan = () => import('@components/plan/edit.vue');
const NewPlan = () => import('@components/plan/new.vue');

// 预警页面
const CautionDetail = () => import('@components/caution/detail.vue');
const CautionList = () => import('@components/caution/list.vue');

// 舆情分析 demo
const AnalysisEmotionDemo = () => import('@components/analysis/demo/emotion_v2.vue');
// const AnalysisHotDemo = () => import('@components/analysis/demo/hot.vue');
const AnalysisHotDemo = () => import('@components/analysis/demo/hot_v2.vue');
const AnalysisHotDemoV3 = () => import('@components/analysis/demo/hot_v3.vue');
const AnalysisPropagationNetworkDemo = () => import('@components/analysis/demo/propagation-network.vue');
const AnalysisPropagationWeiboDemo = () => import('@components/analysis/demo/propagation-weibo.vue');
const AnalysisViewDemo = () => import('@components/analysis/demo/view_v2.vue');
const Analysis = () => import('@components/analysis/analysis.vue');

// 與情分析v2版本
const AnalysisHotV2 = () => import('@components/analysis/view/v2/hot.vue');
const AnalysisEmotionV2 = () => import('@components/analysis/view/v2/emotion.vue');
const AnalysisViewV2 = () => import('@components/analysis/view/v2/view.vue');
const AnalysisPropagationV2 = () => import('@components/analysis/view/v2/propagation-network.vue');
const AnalysisPropagationWeiboV2 = () => import('@components/analysis/view/v2/propagation-weibo.vue');

// 舆情分析V3版本
const AnalysisHotV3 = () => import('@components/analysis/view/v3/hot.vue');
const AnalysisEmotionV3 = () => import('@components/analysis/view/v3/emotion.vue');
const AnalysisViewV3 = () => import('@components/analysis/view/v3/view.vue');



// 舆情比较
const Compare = () => import('@components/compare/compare.vue');
const CompareView = () => import('@components/compare/view/view.vue');

// 智能简报
const Autobrief = () => import('@components/autobrief/autobrief.vue');
const AbstractBriefExample = () =>
  import('@components/autobrief/template/day/abstract.vue');
const ClassicWeekExample = () =>
  import('@components/autobrief/template/week/classic.vue');
// const ClassicDayExample = () => import('@components/autobrief/template/day/classic.vue');
// const AdvancedDayExample = () => import('@components/autobrief/template/day/advanced.vue');
const ClassicWeekH5 = () => import('@components/autobrief/h5/week/classic.vue');
const AbstractWeekH5 = () =>
  import('@components/autobrief/h5/week/abstract.vue');
const ClassicDayH5 = () => import('@components/autobrief/h5/day/classic.vue');
const AdvancedDayH5 = () => import('@components/autobrief/h5/day/advanced.vue');
const AbstractDayH5 = () => import('@components/autobrief/h5/day/abstract.vue');

// 简报
const AdvancedBriefExample = () =>
  import('@components/brief/template/advanced.vue');
const ClassicBriefExample = () =>
  import('@components/brief/template/classic.vue');
const CommonBriefExample = () =>
  import('@components/brief/template/daily_common.vue');
const MixBriefExample = () =>
  import('@components/brief/template/daily_mix.vue');
const AdvancedBriefH5 = () => import('@components/brief/h5/advanced.vue');
const AbstractBriefH5 = () => import('@components/brief/h5/abstract.vue');
const ClassicBriefH5 = () => import('@components/brief/h5/classic.vue');
const CommonBriefH5 = () => import('@components/brief/h5/common.vue');
const MixBriefH5 = () => import('@components/brief/h5/mix.vue');

// 全网总览
const Overview = () => import('@components/overview/overview.vue');

// 信源信息
const Source = () => import('@components/source/source.vue');
const SourcePlanCreate = () =>
  import('@components/source/components/create-plan.vue');
const SourcePlanUpdate = () =>
  import('@components/source/components/update-plan.vue');

// 行业纵览
const Repository = () => import('@components/repository/list.vue');

// 军情
const Military = () => import('@components/military/military.vue');

// 全网智搜
const Search = () => import('@components/search/search.vue');
const SearchIndex = () => import('@components/search/index.vue');

// 账号管理
const Account = () => import('@components/setting/account.vue');

// 联系人
const Contact = () => import('@components/setting/contact.vue');

// 预警管理
const Caution = () => import('@components/caution/index.vue');
//屏蔽管理
const Shield = () => import('@components/setting/shield.vue');

// 素材库
const Material = () => import('@components/setting/material.vue');

// 定向监测
const Monitor = () => import('@components/setting/source/source.vue');

// 404
const NotFound = () => import('@components/error/404.vue');

// 新手引导
const BeginnerGuidance = () =>
  import('@components/common/beginner-guidance.vue');

// 定向信源方案
const OrientationList = () => import('@components/orientation/list.vue');
const OrientationCreateInclude = () =>
  import('@components/orientation/create-include.vue');
const OrientationCreateExclude = () =>
  import('@components/orientation/create-exclude.vue');
const OrientationUpdate = () => import('@components/orientation/update.vue');
// 信源预警

const SourceCaution = () => import('@components/source/source-caution.vue');
// 简报制作v2.1.0
const BriefV2 = () => import('@components/brief_v2/index.vue');
// 自定义简报
const CustomBrief = () => import('@components/brief_v2/custom/index.vue');
// 方案大屏
const PlanScreen = () => import('@components/plan/screen.vue');

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/login',
      component: Login,
      name: 'login'
    },
    {
      // 云登录
      path: '/cloud_auth',
      name: 'cloud_auth'
    },
    {
      path: '/compareview',
      component: CompareView,
      name: 'compare_view'
    },
    {
      path: '/brief/example/classic',
      component: ClassicBriefExample,
      name: 'brief_example_classic'
    },
    {
      path: '/brief/example/advanced',
      component: AdvancedBriefExample,
      name: 'brief_example_advanced'
    },
    {
      path: '/brief/example/common',
      component: CommonBriefExample,
      name: 'brief_example_common'
    },
    {
      path: '/brief/example/mix',
      component: MixBriefExample,
      name: 'brief_example_mix'
    },
    {
      path: '/brief/example/abstract',
      component: AbstractBriefExample,
      name: 'brief_example_abstract'
    },
    {
      path: '/brief/h5/mix',
      component: MixBriefH5,
      name: 'brief_h5_mix'
    },
    {
      path: '/brief/h5/common',
      component: CommonBriefH5,
      name: 'brief_h5_common'
    },
    {
      path: '/brief/h5/advanced',
      component: AdvancedBriefH5,
      name: 'brief_h5_advanced'
    },
    {
      path: '/brief/h5/abstract',
      component: AbstractBriefH5,
      name: 'brief_h5_abstract'
    },
    {
      path: '/brief/h5/classic',
      component: ClassicBriefH5,
      name: 'brief_h5_classic'
    },
    {
      path: '/autobriefing/day/abstract',
      component: AbstractBriefExample,
      name: 'autobrief_day_abstract'
    },
    {
      path: '/autobriefing/week/abstract',
      component: AbstractBriefExample,
      name: 'autobrief_week_abstract'
    },
    {
      path: '/autobriefing/day/advanced',
      component: AdvancedBriefExample,
      name: 'autobrief_day_advanced'
    },
    {
      path: '/autobriefing/day/classic',
      component: ClassicBriefExample,
      name: 'autobrief_day_classic'
    },
    {
      path: '/autobriefing/week/classic',
      component: ClassicWeekExample,
      name: 'autobrief_week_classic'
    },
    {
      path: '/autobriefing/day/h5abstract',
      component: AbstractDayH5,
      name: 'autobrief_day_h5abstract'
    },
    {
      path: '/autobriefing/day/h5advanced',
      component: AdvancedDayH5,
      name: 'autobrief_day_h5advanced'
    },
    {
      path: '/autobriefing/day/h5classic',
      component: ClassicDayH5,
      name: 'autobrief_day_h5classic'
    },
    {
      path: '/autobriefing/week/h5classic',
      component: ClassicWeekH5,
      name: 'autobrief_week_h5classic'
    },
    {
      path: '/autobriefing/week/h5abstract',
      component: AbstractWeekH5,
      name: 'autobrief_week_h5abstract'
    },
    {
      path: '/caution/list',
      component: CautionList,
      name: 'caution_list'
    },
    {
      path: '/analysis/hot_v2',
      component: AnalysisHotV2,
      name: 'analysis_hot_v2'
    },
    {
      path: '/analysis/emotion_v2',
      component: AnalysisEmotionV2,
      name: 'analysis_emotion_v2'
    },
    {
      path: '/analysis/propagation_weibo_v2',
      component: AnalysisPropagationWeiboV2,
      name: 'analysis_propagation_weibo_v2'
    },
    {
      path: '/analysis/propagation_v2',
      component: AnalysisPropagationV2,
      name: 'analysis_propagation_v2'
    },
    {
      path: '/analysis/view_v2',
      component: AnalysisViewV2,
      name: 'analysis_view_v2'
    },
    {
      path: '/analysis/hot_v3',
      component: AnalysisHotV3,
      name: 'analysis_hot_v3'
    },
    {
      path: '/analysis/emotion_v3',
      component: AnalysisEmotionV3,
      name: 'analysis_emotion_v3'
    },{
      path: '/analysis/view_v3',
      component: AnalysisViewV3,
      name: 'analysis_view_v3'
    },
    {
      path: '/analysis/emotion_demo',
      component: AnalysisEmotionDemo,
      name: 'analysis_emotion_demo'
    },
    {
      path: '/analysis/propagation_demo',
      component: AnalysisPropagationNetworkDemo,
      name: 'analysis_propagation_demo'
    },
    {
      path: '/analysis/propagation_weibo_demo',
      component: AnalysisPropagationWeiboDemo,
      name: 'analysis_propagation_weibo_demo'
    },
    {
      path: '/analysis/hot_demo',
      component: AnalysisHotDemo,
      name: 'analysis_hot_demo'
    },
    {
      path: '/analysis/hot_demo_v3',
      component: AnalysisHotDemoV3,
      name: 'analysis_hot_demo_v3'
    },
    {
      path: '/analysis/view_demo',
      component: AnalysisViewDemo,
      name: 'analysis_view_demo'
    },
    {
      path: '/plan/new',
      component: NewPlan,
      name: 'new_plan'
    },
    {
      path: '/plan/edit',
      component: EditPlan,
      name: 'edit_plan'
    },
    {
      path: '/',
      component: Index,
      children: [{
          path: '/',
          component: Overview,
          name: 'index'
        },
        {
          path: 'monitor',
          component: Monitor,
          name: 'monitor'
        },
        {
          path: 'orientation',
          component: OrientationList,
          name: 'orientation'
        },
        {
          path: 'orientation/include',
          component: OrientationCreateInclude,
          name: 'orientation-include'
        },
        {
          path: 'orientation/exclude',
          component: OrientationCreateExclude,
          name: 'orientation-exclude'
        },
        {
          path: 'orientation/update',
          component: OrientationUpdate,
          name: 'orientation-update'
        },
        {
          path: 'caution',
          component: Caution,
          name: 'caution'
        },
        {
          path: '/shield',
          component: Shield,
          name: 'shield'
        },
        {
          path: 'material',
          component: Material,
          name: 'material'
        },
        {
          path: 'account',
          component: Account,
          name: 'account'
        },
        {
          path: 'contact',
          component: Contact,
          name: 'contact'
        },
        {
          path: 'overview',
          component: Overview,
          name: 'overview'
        },
        {
          path: 'yq',
          component: Yq,
          name: 'yq'
        },
        {
          path: 'plan_overview',
          component: PlanOverview,
          name: 'plan_overview'
        },
        {
          path: 'analysis',
          component: Analysis,
          name: 'analysis'
        },
        {
          path: 'compare',
          component: Compare,
          name: 'compare'
        },
        {
          path: 'autobrief',
          component: Autobrief,
          name: 'autobrief'
        },
        {
          path: 'source',
          component: Source,
          name: 'source'
        },
        {
          path: 'source/create',
          component: SourcePlanCreate,
          name: 'source-create-plan'
        },
        {
          path: 'source/update',
          component: SourcePlanUpdate,
          name: 'source-update-plan'
        },
        {
          path: 'search',
          component: Search,
          name: 'search'
        },
        {
          path: 'search/index',
          component: SearchIndex,
          name: 'searchIndex'
        },
        {
          path: 'military',
          component: Military,
          name: 'military'
        },
        {
          path: 'repository',
          component: Repository,
          name: 'repository'
        },
        {
          path: 'caution/detail',
          component: CautionDetail,
          name: 'caution_detail'
        },
        {
          path: '/beginner-guidance',
          component: BeginnerGuidance,
          name: 'beginner-guidance'
        },
        {
          path: '/source-caution',
          component: SourceCaution,
          name: 'source-caution'
        },
        {
          path: '/brief',
          component: BriefV2,
          name: 'brief'
        }, {
          path: '/brief/custom-brief',
          component: CustomBrief,
          name: 'custom-brief'
        }
      ]
    },
    {
      path: '*',
      component: NotFound,
      name: 'notfound'
    }, {
      path: '/plan-screen',
      component: PlanScreen,
      name: 'plan-screen'
    }
  ]
})
