import html2canvas from 'html2canvas'
import { Message } from 'element-ui'
const FileSaver = require('file-saver');
const qs = require('querystringify');
// 项目echarts,d3依赖
const echarts = require('echarts');
require('echarts-wordcloud');
require('@/china.js');


export function formatMsgArea(row) {
  if (row.country === '中国') {
    return (row.province === '' || row.province === '其他') ? '中国' : row.province;
  } else {
    return row.country;
  }
}

export function queryString(objectParams, tag) {
  return qs.stringify(objectParams, tag);
}

export function localDbGet(name) {
  return localStorage.getItem(name);
}

export function localDbSet(name, value) {
  return localStorage.setItem(name, value);
}

export function localDbRemove(name) {
  return localStorage.removeItem(name);
}

export function localDbClear() {
  return localStorage.clear();
}

// 设置认证获取的 token
export function setAuthToken(token) {
  localStorage.setItem('szyq_auth_token', token);
}

// 获取保存的认证 token
export function getAuthToken() {
  return localStorage.getItem('szyq_auth_token');
}

export function globalCreateChart(element, option, notMerge = false) {
  let chart = echarts.init(element)
  if (notMerge) {
    chart.setOption(option, true)
  } else {
    chart.setOption(option)
  }

  return chart
}

export function globalGetEcharts() {
  return echarts;
}

export function globalMediaColor(media) {
  let color = null

  switch (media) {
    case '全部':
      color = '#0068b7'
      break
    case '微信':
      color = '#66CB03'
      break
    case '微博':
      color = '#FFB146'
      break
    case '网媒':
      color = '#4E88FE'
      break
    case '新闻':
      color = "#2a38d8"
      break
    case '论坛':
      color = '#76D4FF'
      break
    case '报刊':
      color = '#74CCB1'
      break
    case '博客':
      color = '#62AFFE'
      break
    case '政务':
      color = '#FCD41A'
      break
    case '客户端':
      color = '#FF9587'
      break
    case '推特':
      color = '#26B3D8'
      break
    case '脸书':
      color = '#FEBA80'
      break
    case '自媒体号':
      color = '#8F65DD'
      break
    case '评论':
      color = '#90AEFF'
      break
    case '视频':
      color = '#8186D5'
      break
    default:
      var colors = ['#fbe44d', '#ee8131']
      var index = Math.floor((Math.random() * colors.length))
      color = colors[index]
      break
  }

  return color
}

export function globalFormatDate(date, hoursZero = false){
  return hoursZero ? window.$moment(date).format('YYYY-MM-DD') + ' 00:00:00' : window.$moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export function isEmail(str) {
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(str) && str.length <= 30){
    return true;
  }

  return false;
}


export function isQQ(str) {
  let reg = /^[1-9][0-9]{4,9}$/
  if (reg.test(str)) {
    return true
  } else {
    return false
  }
}

export function isPhone(phone) {
  if (/^1[345789]\d{9}$/.test(phone)) {
    return true
  }

  return false
}

export function findPointImg(name) {
  let img = ""

  switch (name) {
    case "开始点":
      img = "/static/img/kaishi.svg";
      break;
    case "爆发点":
      img = "/static/img/baofa.svg";
      break;
    case "高峰点":
      img = "/static/img/gaofeng.svg";
      break;
    case "衰退点":
      img = "/static/img/shuaitui.svg";
      break;
    case "分界点":
      img = "/static/img/fenjie.svg";
      break;
    case "最低点":
      img = "/static/img/zuidi.svg";
      break;
    case "结束点":
      img = "/static/img/jieshu.svg";
      break;
  }

  return img
}

export function numberFormat(num) {
  let reg = /\d{1,3}(?=(\d{3})+$)/g;

  return num.toString().replace(reg, '$&,');
}

export function dateRangeDisabled(date) {
  // 最大时间限制当日 23:59:59
  let initEnd = window.$moment().format('YYYY-MM-DD');
  let end = new Date(window.$moment(initEnd + ' 23:59:59').valueOf());
  let minTimeBuff = window.$moment().subtract(90, 'days').format('YYYY-MM-DD') + ' 00:00:00'
  let maxTimeBuff = window.$moment(end).format("YYYY-MM-DD HH:mm:ss")


  let rTime = new Date(date).getTime()
  let minTime = new Date(window.$moment(minTimeBuff).valueOf()).getTime()
  let maxTime = new Date(window.$moment(maxTimeBuff).valueOf()).getTime()

  if ((rTime < minTime) || (rTime > maxTime)) return true

  return false
}

export function sourceFocusId(params) {
  if (params.source_focus_type === 'exclude') {
    params.source_focus = params.source_focus_exclude_id ? params.source_focus_exclude_id : null;
  } else if (params.source_focus_type === 'include') {
    params.source_focus = params.source_focus_include_id ? params.source_focus_include_id : null;
  } else {
    params.source_focus = null;
  }

  return params.source_focus;
}

export function dom2image(dom, filename) {
  const scale = 1;
  dom.style.boxShadow = 'none';

  const canvas = document.createElement("canvas");
  canvas.width = dom.offsetWidth * scale;
  canvas.height = dom.offsetHeight * scale;
  canvas.getContext("2d").scale(scale, scale);

  const context = canvas.getContext("2d");

  // 关闭抗锯齿
  context.mozImageSmoothingEnabled = false;
  context.webkitImageSmoothingEnabled = false;
  context.msImageSmoothingEnabled = false;
  context.imageSmoothingEnabled = false;

  html2canvas(dom, {
    scale,
    useCORS: true,
    allowTaint: false,
    timeout: 3000,
    backgroundColor: 'white',
    width: dom.offsetWidth * scale,
    height: dom.offsetHeight * scale,
    canvas,
    scrollX: 0,
    scrollY: -window.scrollY
  }).then((canvas) => {
    let dataURL = canvas.toDataURL("image/png");
    let aLink = document.createElement("a");
    dom.style.boxShadow = '0 2px 12px 0 rgba(0,0,0,.1)';

    aLink.style.display = "none";
    aLink.href = dataURL;
    aLink.download = filename;
    // 触发点击-然后移除
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);
  });
}

export function enCountry2zh(enCountry) {
  let maps = {
    Afghanistan: '阿富汗',
    Singapore: '新加坡',
    Angola: '安哥拉',
    Albania: '阿尔巴尼亚',
    'United Arab Emirates': '阿联酋',
    Argentina: '阿根廷',
    Armenia: '亚美尼亚',
    'French Southern and Antarctic Lands': '法属南半球和南极领地',
    Australia: '澳大利亚',
    Austria: '奥地利',
    Azerbaijan: '阿塞拜疆',
    Burundi: '布隆迪',
    Belgium: '比利时',
    Benin: '贝宁',
    'Burkina Faso': '布基纳法索',
    Bangladesh: '孟加拉国',
    Bulgaria: '保加利亚',
    'The Bahamas': '巴哈马',
    'Bosnia and Herzegovina': '波斯尼亚和黑塞哥维那',
    Belarus: '白俄罗斯',
    Belize: '伯利兹',
    Bermuda: '百慕大',
    Bolivia: '玻利维亚',
    Brazil: '巴西',
    Brunei: '文莱',
    Bhutan: '不丹',
    Botswana: '博茨瓦纳',
    'Central African Republic': '中非共和国',
    Canada: '加拿大',
    Switzerland: '瑞士',
    Chile: '智利',
    China: '中国',
    'Ivory Coast': '象牙海岸',
    Cameroon: '喀麦隆',
    'Democratic Republic of the Congo': '刚果民主共和国',
    'Republic of the Congo': '刚果共和国',
    Colombia: '哥伦比亚',
    'Costa Rica': '哥斯达黎加',
    Cuba: '古巴',
    'Northern Cyprus': '北塞浦路斯',
    Cyprus: '塞浦路斯',
    'Czech Republic': '捷克共和国',
    Germany: '德国',
    Djibouti: '吉布提',
    Denmark: '丹麦',
    'Dominican Republic': '多明尼加共和国',
    Algeria: '阿尔及利亚',
    Ecuador: '厄瓜多尔',
    Egypt: '埃及',
    Eritrea: '厄立特里亚',
    Spain: '西班牙',
    Estonia: '爱沙尼亚',
    Ethiopia: '埃塞俄比亚',
    Finland: '芬兰',
    Fiji: '斐',
    'Falkland Islands': '福克兰群岛',
    France: '法国',
    Gabon: '加蓬',
    'United Kingdom': '英国',
    Georgia: '格鲁吉亚',
    Ghana: '加纳',
    Guinea: '几内亚',
    Gambia: '冈比亚',
    'Guinea Bissau': '几内亚比绍',
    Greece: '希腊',
    Greenland: '格陵兰',
    Guatemala: '危地马拉',
    'French Guiana': '法属圭亚那',
    Guyana: '圭亚那',
    Honduras: '洪都拉斯',
    Croatia: '克罗地亚',
    Haiti: '海地',
    Hungary: '匈牙利',
    Indonesia: '印度尼西亚',
    India: '印度',
    Ireland: '爱尔兰',
    Iran: '伊朗',
    Iraq: '伊拉克',
    Iceland: '冰岛',
    Israel: '以色列',
    Italy: '意大利',
    Jamaica: '牙买加',
    Jordan: '约旦',
    Japan: '日本',
    Kazakhstan: '哈萨克斯坦',
    Kenya: '肯尼亚',
    Kyrgyzstan: '吉尔吉斯斯坦',
    Cambodia: '柬埔寨',
    Kosovo: '科索沃',
    Kuwait: '科威特',
    Laos: '老挝',
    Lebanon: '黎巴嫩',
    Liberia: '利比里亚',
    Libya: '利比亚',
    'Sri Lanka': '斯里兰卡',
    Lesotho: '莱索托',
    Lithuania: '立陶宛',
    Luxembourg: '卢森堡',
    Latvia: '拉脱维亚',
    Morocco: '摩洛哥',
    Moldova: '摩尔多瓦',
    Madagascar: '马达加斯加',
    Mexico: '墨西哥',
    Macedonia: '马其顿',
    Mali: '马里',
    Myanmar: '缅甸',
    Montenegro: '黑山',
    Mongolia: '蒙古',
    Mozambique: '莫桑比克',
    Mauritania: '毛里塔尼亚',
    Malawi: '马拉维',
    Malaysia: '马来西亚',
    Namibia: '纳米比亚',
    'New Caledonia': '新喀里多尼亚',
    Niger: '尼日尔',
    Nigeria: '尼日利亚',
    Nicaragua: '尼加拉瓜',
    Netherlands: '荷兰',
    Norway: '挪威',
    Nepal: '尼泊尔',
    'New Zealand': '新西兰',
    Oman: '阿曼',
    Pakistan: '巴基斯坦',
    Panama: '巴拿马',
    Peru: '秘鲁',
    Philippines: '菲律宾',
    'Papua New Guinea': '巴布亚新几内亚',
    Poland: '波兰',
    'Puerto Rico': '波多黎各',
    'North Korea': '北朝鲜',
    Portugal: '葡萄牙',
    Paraguay: '巴拉圭',
    Qatar: '卡塔尔',
    Romania: '罗马尼亚',
    Russia: '俄罗斯',
    Rwanda: '卢旺达',
    'Western Sahara': '西撒哈拉',
    'Saudi Arabia': '沙特阿拉伯',
    Sudan: '苏丹',
    'South Sudan': '南苏丹',
    Senegal: '塞内加尔',
    'Solomon Islands': '所罗门群岛',
    'Sierra Leone': '塞拉利昂',
    'El Salvador': '萨尔瓦多',
    Somaliland: '索马里兰',
    Somalia: '索马里',
    'Republic of Serbia': '塞尔维亚',
    Suriname: '苏里南',
    Slovakia: '斯洛伐克',
    Slovenia: '斯洛文尼亚',
    Sweden: '瑞典',
    Swaziland: '斯威士兰',
    Syria: '叙利亚',
    Chad: '乍得',
    Togo: '多哥',
    Thailand: '泰国',
    Tajikistan: '塔吉克斯坦',
    Turkmenistan: '土库曼斯坦',
    'East Timor': '东帝汶',
    'Trinidad and Tobago': '特里尼达和多巴哥',
    Tunisia: '突尼斯',
    Turkey: '土耳其',
    'United Republic of Tanzania': '坦桑尼亚',
    Uganda: '乌干达',
    Ukraine: '乌克兰',
    Uruguay: '乌拉圭',
    'United States': '美国',
    Uzbekistan: '乌兹别克斯坦',
    Venezuela: '委内瑞拉',
    Vietnam: '越南',
    Vanuatu: '瓦努阿图',
    'West Bank': '西岸',
    Yemen: '也门',
    'South Africa': '南非',
    Zambia: '赞比亚',
    Korea: '韩国',
    Tanzania: '坦桑尼亚',
    Zimbabwe: '津巴布韦',
    Congo: '刚果',
    'Central African Rep.': '中非',
    Serbia: '塞尔维亚',
    'Bosnia and Herz.': '波黑',
    'Czech Rep.': '捷克',
    'W. Sahara': '西撒哈拉',
    'Lao PDR': '老挝',
    'Dem.Rep.Korea': '朝鲜',
    'Falkland Is.': '福克兰群岛',
    'Timor-Leste': '东帝汶',
    'Solomon Is.': '所罗门群岛',
    Palestine: '巴勒斯坦',
    'N. Cyprus': '北塞浦路斯',
    Aland: '奥兰群岛',
    'Fr. S. Antarctic Lands': '法属南半球和南极陆地',
    Mauritius: '毛里求斯',
    Comoros: '科摩罗',
    'Eq. Guinea': '赤道几内亚',
    'Guinea-Bissau': '几内亚比绍',
    'Dominican Rep.': '多米尼加',
    'Saint Lucia' : '圣卢西亚',
    Dominica: '多米尼克',
    'Antigua and Barb.': '安提瓜和巴布达',
    'U.S. Virgin Is.': '美国原始岛屿',
    Montserrat: '蒙塞拉特',
    Grenada: '格林纳达',
    Barbados: '巴巴多斯',
    Samoa: '萨摩亚',
    Bahamas: '巴哈马',
    'Cayman Is.': '开曼群岛',
    'Faeroe Is.': '法罗群岛',
    'IsIe of Man': '马恩岛',
    Malta: '马耳他共和国',
    Jersey: '泽西',
    'Cape Verde': '佛得角共和国',
    'Turks and Caicos Is.': '特克斯和凯科斯群岛',
    'St. Vin. and Gren.': '圣文森特和格林纳丁斯'
  }

  return maps[enCountry]
}

export function countryMap(countryName) {
  let maps = {
    Afghanistan: '阿富汗',
    Singapore: '新加坡',
    Angola: '安哥拉',
    Albania: '阿尔巴尼亚',
    'United Arab Emirates': '阿联酋',
    Argentina: '阿根廷',
    Armenia: '亚美尼亚',
    'French Southern and Antarctic Lands': '法属南半球和南极领地',
    Australia: '澳大利亚',
    Austria: '奥地利',
    Azerbaijan: '阿塞拜疆',
    Burundi: '布隆迪',
    Belgium: '比利时',
    Benin: '贝宁',
    'Burkina Faso': '布基纳法索',
    Bangladesh: '孟加拉国',
    Bulgaria: '保加利亚',
    'The Bahamas': '巴哈马',
    'Bosnia and Herzegovina': '波斯尼亚和黑塞哥维那',
    Belarus: '白俄罗斯',
    Belize: '伯利兹',
    Bermuda: '百慕大',
    Bolivia: '玻利维亚',
    Brazil: '巴西',
    Brunei: '文莱',
    Bhutan: '不丹',
    Botswana: '博茨瓦纳',
    'Central African Republic': '中非共和国',
    Canada: '加拿大',
    Switzerland: '瑞士',
    Chile: '智利',
    China: '中国',
    'Ivory Coast': '象牙海岸',
    Cameroon: '喀麦隆',
    'Democratic Republic of the Congo': '刚果民主共和国',
    'Republic of the Congo': '刚果共和国',
    Colombia: '哥伦比亚',
    'Costa Rica': '哥斯达黎加',
    Cuba: '古巴',
    'Northern Cyprus': '北塞浦路斯',
    Cyprus: '塞浦路斯',
    'Czech Republic': '捷克共和国',
    Germany: '德国',
    Djibouti: '吉布提',
    Denmark: '丹麦',
    'Dominican Republic': '多明尼加共和国',
    Algeria: '阿尔及利亚',
    Ecuador: '厄瓜多尔',
    Egypt: '埃及',
    Eritrea: '厄立特里亚',
    Spain: '西班牙',
    Estonia: '爱沙尼亚',
    Ethiopia: '埃塞俄比亚',
    Finland: '芬兰',
    Fiji: '斐',
    'Falkland Islands': '福克兰群岛',
    France: '法国',
    Gabon: '加蓬',
    'United Kingdom': '英国',
    Georgia: '格鲁吉亚',
    Ghana: '加纳',
    Guinea: '几内亚',
    Gambia: '冈比亚',
    'Guinea Bissau': '几内亚比绍',
    Greece: '希腊',
    Greenland: '格陵兰',
    Guatemala: '危地马拉',
    'French Guiana': '法属圭亚那',
    Guyana: '圭亚那',
    Honduras: '洪都拉斯',
    Croatia: '克罗地亚',
    Haiti: '海地',
    Hungary: '匈牙利',
    Indonesia: '印度尼西亚',
    India: '印度',
    Ireland: '爱尔兰',
    Iran: '伊朗',
    Iraq: '伊拉克',
    Iceland: '冰岛',
    Israel: '以色列',
    Italy: '意大利',
    Jamaica: '牙买加',
    Jordan: '约旦',
    Japan: '日本',
    Kazakhstan: '哈萨克斯坦',
    Kenya: '肯尼亚',
    Kyrgyzstan: '吉尔吉斯斯坦',
    Cambodia: '柬埔寨',
    Kosovo: '科索沃',
    Kuwait: '科威特',
    Laos: '老挝',
    Lebanon: '黎巴嫩',
    Liberia: '利比里亚',
    Libya: '利比亚',
    'Sri Lanka': '斯里兰卡',
    Lesotho: '莱索托',
    Lithuania: '立陶宛',
    Luxembourg: '卢森堡',
    Latvia: '拉脱维亚',
    Morocco: '摩洛哥',
    Moldova: '摩尔多瓦',
    Madagascar: '马达加斯加',
    Mexico: '墨西哥',
    Macedonia: '马其顿',
    Mali: '马里',
    Myanmar: '缅甸',
    Montenegro: '黑山',
    Mongolia: '蒙古',
    Mozambique: '莫桑比克',
    Mauritania: '毛里塔尼亚',
    Malawi: '马拉维',
    Malaysia: '马来西亚',
    Namibia: '纳米比亚',
    'New Caledonia': '新喀里多尼亚',
    Niger: '尼日尔',
    Nigeria: '尼日利亚',
    Nicaragua: '尼加拉瓜',
    Netherlands: '荷兰',
    Norway: '挪威',
    Nepal: '尼泊尔',
    'New Zealand': '新西兰',
    Oman: '阿曼',
    Pakistan: '巴基斯坦',
    Panama: '巴拿马',
    Peru: '秘鲁',
    Philippines: '菲律宾',
    'Papua New Guinea': '巴布亚新几内亚',
    Poland: '波兰',
    'Puerto Rico': '波多黎各',
    'North Korea': '北朝鲜',
    Portugal: '葡萄牙',
    Paraguay: '巴拉圭',
    Qatar: '卡塔尔',
    Romania: '罗马尼亚',
    Russia: '俄罗斯',
    Rwanda: '卢旺达',
    'Western Sahara': '西撒哈拉',
    'Saudi Arabia': '沙特阿拉伯',
    Sudan: '苏丹',
    'South Sudan': '南苏丹',
    Senegal: '塞内加尔',
    'Solomon Islands': '所罗门群岛',
    'Sierra Leone': '塞拉利昂',
    'El Salvador': '萨尔瓦多',
    Somaliland: '索马里兰',
    Somalia: '索马里',
    'Republic of Serbia': '塞尔维亚',
    Suriname: '苏里南',
    Slovakia: '斯洛伐克',
    Slovenia: '斯洛文尼亚',
    Sweden: '瑞典',
    Swaziland: '斯威士兰',
    Syria: '叙利亚',
    Chad: '乍得',
    Togo: '多哥',
    Thailand: '泰国',
    Tajikistan: '塔吉克斯坦',
    Turkmenistan: '土库曼斯坦',
    'East Timor': '东帝汶',
    'Trinidad and Tobago': '特里尼达和多巴哥',
    Tunisia: '突尼斯',
    Turkey: '土耳其',
    'United Republic of Tanzania': '坦桑尼亚',
    Uganda: '乌干达',
    Ukraine: '乌克兰',
    Uruguay: '乌拉圭',
    'United States': '美国',
    Uzbekistan: '乌兹别克斯坦',
    Venezuela: '委内瑞拉',
    Vietnam: '越南',
    Vanuatu: '瓦努阿图',
    'West Bank': '西岸',
    Yemen: '也门',
    'South Africa': '南非',
    Zambia: '赞比亚',
    Korea: '韩国',
    Tanzania: '坦桑尼亚',
    Zimbabwe: '津巴布韦',
    Congo: '刚果',
    'Central African Rep.': '中非',
    Serbia: '塞尔维亚',
    'Bosnia and Herz.': '波黑',
    'Czech Rep.': '捷克',
    'W. Sahara': '西撒哈拉',
    'Lao PDR': '老挝',
    'Dem.Rep.Korea': '朝鲜',
    'Falkland Is.': '福克兰群岛',
    'Timor-Leste': '东帝汶',
    'Solomon Is.': '所罗门群岛',
    Palestine: '巴勒斯坦',
    'N. Cyprus': '北塞浦路斯',
    Aland: '奥兰群岛',
    'Fr. S. Antarctic Lands': '法属南半球和南极陆地',
    Mauritius: '毛里求斯',
    Comoros: '科摩罗',
    'Eq. Guinea': '赤道几内亚',
    'Guinea-Bissau': '几内亚比绍',
    'Dominican Rep.': '多米尼加',
    'Saint Lucia' : '圣卢西亚',
    Dominica: '多米尼克',
    'Antigua and Barb.': '安提瓜和巴布达',
    'U.S. Virgin Is.': '美国原始岛屿',
    Montserrat: '蒙塞拉特',
    Grenada: '格林纳达',
    Barbados: '巴巴多斯',
    Samoa: '萨摩亚',
    Bahamas: '巴哈马',
    'Cayman Is.': '开曼群岛',
    'Faeroe Is.': '法罗群岛',
    'IsIe of Man': '马恩岛',
    Malta: '马耳他共和国',
    Jersey: '泽西',
    'Cape Verde': '佛得角共和国',
    'Turks and Caicos Is.': '特克斯和凯科斯群岛',
    'St. Vin. and Gren.': '圣文森特和格林纳丁斯'
  }
  let name = null
  window._(maps).forEach((v, k) => {
    if (v === countryName) {
      name = k
      return false
    }
  })

  return name
}

/**
 * 时间戳转化
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || 'y-m-d h:i:s';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time);
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/(y|m|d|h|i|s|a)+/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ]; }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

// 将字段转化成日期范围
export function parseDateRange(str, formatter) {
  str = str.toString();
  // 设置枚举
  const map = {
    today: 1,
    '1': 1,
    '2': 2,
    '3': 3,
    '7': 7,
  }
  const num = map[str] || 1;
  const cFormat = formatter || 'yy-mm-dd hh:mm:ss';
  const currTime = new Date().getTime();
  const startTime = currTime - num * 24 * 60 * 60 * 1000;
  return `${parseTime(startTime, cFormat)}~${parseTime(currTime, cFormat)}`
}

// 信源监测 加入监测名单 (排除已有)
export function canJoinListen(listenList, item) {
  // 三种类别 网站=微博=微信
  let res = undefined, data = []

  data = _.filter(listenList, (row) => {
    return row.category === item.category && row.state === item.state
  })

  res = _.find(data, { feature: item.feature })

  return res === undefined
}

/**
 * 添加到定向信源
 * 审核
 */

export function toSourceCheck(data) {
  const { category, mark, submark } = data
  let row = {category: category, mark, submark, state: 'wait'}

  // switch(category) {
  //   case "微博":
  //     row['userid'] = mark
  //     row['author'] = mark
  //     break;
  //   case "微信":
  //     row['author'] = mark
  //     row['username'] = mark
  //     break;
  //   case "网站":
  //     row['host'] = mark
  //     row['source_name'] = mark
  //     break;
  //   case "评论":
  //     row['host'] = mark
  //     row['source_name'] = mark
  //     break;
  //   case "推特":
  //     row['author'] = mark
  //     row['userid'] = mark
  //     row['username'] = mark
  //     break;
  //   case "脸书":
  //     row["author"] = mark
  //     row["username"] = mark
  //     break;
  //   case "论坛":
  //     row['host'] = mark
  //     break;
  //   case "客户端":
  //     row['host'] = mark
  //     break;
  //   case "自媒体号":
  //     row['author'] = mark
  //     break;
  // }

  return row
}

/**
 * 时间区间加时分秒
 * @param span
 * @returns {string}
 */
export function span2hms(span) {
  let spanarr = span.split("~")
  let begin = parseTime(new Date(window.$moment(_.first(spanarr)).valueOf()), 'y-m-d');
  let end = parseTime(new Date(window.$moment(_.last(spanarr)).valueOf()), 'y-m-d');

  return `${begin} 00:00:00~${end} 23:59:59`
}

export function sourceFeature(item) {
  let feature = ''

  if (['wait', 'fail'].indexOf(item.state) !== -1)
  {
    feature = [item.mark, item.submark].join('；')

    return _.trimEnd(feature, '；')
  }

  // 网站包含 网媒/论坛/评论/博客/政务
  if (['网媒', '论坛', '评论', '博客', '政务', '视频'].indexOf(item.category) !== -1)
  {
    feature = `域名：${item.domain}；子域名：${item.subdomain}`

    return feature
  }

  switch (item.category) {
    case "微信":
      feature = `账号：${item.username}`
      break
    case '微博':
      feature = `主页：${item.homepage}`
      break
    case '自媒体号':
      feature = `平台：${item.ztype}；主页：${item.homepage}；昵称：${item.nickname}`
      break
    case '推特':
      feature = `主页：${item.homepage}`
      break
    case '脸书':
    case 'Telegram':
      feature = `主页：${item.homepage}`
      break
    case '客户端':
      feature = `昵称：${item.site}`
      break
  }

  return feature
}

export function sourceAuthor(row)
{
  let author = ''
  if (['wait', 'fail'].indexOf(row.state) !== -1)
  {
    if (row.category === '自媒体号')
    {
      return row.submark
    } else if (['网站', '客户端'].indexOf(row.category) !== -1) {
      return '--'
    }

    return row.mark
  }

  // 网站包含 网媒/论坛/评论/博客/政务
  if (['网媒', '论坛', '评论', '博客', '政务', '视频', '客户端'].indexOf(row.category) !== -1)
  {
    return '--'
  }
  author = row.nickname || row.mark

  return author
}

export function sourceLink(row)
{
  let link = ''
  if (['wait', 'fail'].indexOf(row.state) !== -1)
  {
    return row.state === 'wait' ? '待审核' : '审核失败'
  }

  // 网站包含 网媒/论坛/评论/博客/政务
  if (['网媒', '论坛', '评论', '博客', '政务', '视频'].indexOf(row.category) !== -1)
  {
    return row.subdomain || row.domain
  }

  switch (row.category) {
    case '微博':
      link = row.homepage
      break
    case '微信':
      link = row.username
      break
    case '客户端':
      link = '--'
      break
    case '自媒体号':
      link = row.homepage
      break
    case '推特':
      link = row.homepage
      break
    case '脸书':
    case 'Telegram':
      link = row.homepage
      break
  }

  return link
}

export function sourceOpenLink(row)
{
  if (['wait', 'fail'].indexOf(row.state) !== -1)
  {
    return false
  }

  if (['网媒', '论坛', '评论', '博客', '政务', '视频', '微博', '自媒体号', '推特', '脸书', 'Telegram'].indexOf(row.category) !== -1)
  {
    const url = sourceLink(row)
    if (url.length > 1) {
      let link = ''
      if (/^http/.test(url)) {
        link = url
      } else {
        link = `http://${url}`
      }

      window.open(link, '_blank')
    }
  }

  return false
}

export function sourceName(row) {

  if (['wait', 'fail'].indexOf(row.state) !== -1)
  {
    if (['自媒体号', '客户端', '网站'].indexOf(row.category) !== -1)
    {
      return row.mark
    }

    return row.category
  }

  let name = ''

  // 网站包含 网媒/论坛/评论/博客/政务
  if (['网媒', '论坛', '评论', '博客', '政务', '视频'].indexOf(row.category) !== -1)
  {
    name = row.site

    return name
  }

  switch (row.category) {
    case '微博':
      name = '微博'
      break
    case '微信':
      name = '微信'
      break
    case '客户端':
      name = row.site
      break
    case '自媒体号':
      name = row.ztype
      break
    case '推特':
      name = '推特'
      break
    case '脸书':
      name = '脸书'
      break
    case 'Telegram':
      name = 'Telegram'
      break
  }

  return name
}

export function sourceMarkPlaceholder(type)
{
  let placeholder = ''
  switch (type)
  {
    case '网站':
      placeholder = '请输入网站名称或网址'
      break
    case '微博':
      placeholder = '请输入微博昵称或微博ID'
      break
    case '微信':
      placeholder = '请输入微信公众号名称或微信号'
      break
    case '客户端':
      placeholder = '请输入App名称'
      break
    case '推特':
      placeholder = '请输入推特账号或昵称'
      break
    case '脸书':
      placeholder = '请输入脸书账号或昵称'
      break
    case 'Telegram':
      placeholder = '请输入Telegram账号'
      break
  }

  return placeholder
}

export function getQueryVariable(fullUrl, variable) {
  var index = fullUrl.indexOf('?');
  if (index === -1) return "";

  var query = fullUrl.substring(index + 1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] == variable){return pair[1];}
  }
  return "";
}

function getBlob(url, method = 'GET', data = null) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        reject()
      }
    }
    xhr.send(data)
  })
}

export function handleDownload(url, method, filename, data = null, total = 0, callback = () => {}) {
  if (total <= 0) {
    Message({
      type: 'warning',
      message: '导出信息数量为0条',
      showClose: true,
      duration: 3000
    })

    return false
  }
  let msgBuff = null
  const timestamp = new Date().getTime()

  if (total > 10000) {
    msgBuff = Message({
      type: 'warning',
      message: '信息数量超过10000条，正在为您导出前10000条信息。如您需要导出更多数据，请联系运营人员。',
      showClose: true,
      duration: 0
    }, 3000)
  } else {
    msgBuff = Message({
      type: 'warning',
      message: `正在为您导出${total}条信息。`,
      showClose: true,
      duration: 0
    })
  }

  getBlob(url, method, data).then(blob => {
    const diff = new Date().getTime() - timestamp
    if (diff < 3000) {
      setTimeout(() => {
        msgBuff.close()
      }, 3000 - diff)
    } else {
      msgBuff.close()
    }
    callback()
    FileSaver.saveAs(blob, filename)
  }).catch(e => {
    callback()
    msgBuff.close()
    Message({
      type: 'error',
      message: '导出数据失败，服务错误。',
      duration: 3000
    })
  })
}

export function isHotData(timestamp) {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)

  timestamp = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp

  return parseInt(timestamp) >= date.getTime()
}

export function getMenuForId(menus, id) {
  let menuData = []

  _(menus).forEach((menu) => {
    menuData = menuData.concat(menu)
    if (menu.hasOwnProperty('children')) {
      menuData = menuData.concat(menu.children)
    }
  })

  const menu = window._.find(menuData, { id })

  return menu
}
