import {lists as materialList} from '@/api/material'
import { getSourceList as getSourceListReq } from '@/api/plan'
import { userState, getPlans } from '@/api/logined'
import { Message } from 'element-ui';

const user = {
  state: {
    user: {},
    plans: [],
    materials: [],
    bundle: {
      "root_user": {
        "id": 47,
        "name": "zhangsan"
      },
      "expired": "2020-01-31",
      "bundle_name": "全模块",
      "accounts": {
        "used": 20,
        "remain": 11
      },
      "contacts": {
        "used": 10,
        "remain": 4
      },
      "analyses": {
        "used": 1000,
        "remain": 650
      },
      "plans": {
        "used": 100,
        "remain": 68
      }
    },
    menus: [],
    menusPowerId: [],
    hasBriefMode: false,
    sourceList: {exclude: [], include: []}
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_BUNDLE: (state, bundle) => {
      state.bundle = bundle;
    },
    SET_PLANS: (state, plans) => {
      state.plans = plans;
    },
    SET_MATERIALS: (state, materials) => {
      state.materials = materials;
    },
    SET_MENUS: (state, menus) => {
      const briefId = window._.findIndex(menus, {id: 6})
      state.hasBriefMode = briefId === -1 ? false : true
      state.menus = menus;
    },
    SET_MENUSPOWERID: (state, mids) => {
      state.menusPowerId = mids;
    },
    SET_SOURCELIST: (state, data) => {
      state.sourceList = data;
    }
  },
  actions: {
    storeUser({ commit }, user) {
      commit('SET_USER', user)
    },
    storeBundle({ commit }, bundle) {
      commit('SET_BUNDLE', bundle)
    },
    userInfo({ commit }) {
      return new Promise(function(resolve, reject) {
        userState().then(res => {
          if (res.data.state) {
            const user = res.data.data
            commit('SET_USER', user)
            resolve()
          } else {
            reject()
          }
        }).catch(err => {
          reject()
        })
      })
    },
    planList({ commit }) {
      return new Promise((resolve, reject) => {
        getPlans({ enable: 'enable', type: 'expr' }).then(response => {
          const {state, data, error} = response.data;

          if (state) {
            commit('SET_PLANS', data);
            resolve(data);
          } else {
            Message.error(error);
            reject(error);
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
    getSourceList({ commit }) {
      return new Promise((resolve, reject) => {
        getSourceListReq({ page: 1, size: 1000 }).then(response => {
          const { state, error, data } = response.data;
          if (state) {
            let excludeList = [];
            let includeList = [];

            data.map(item => {
              const {name, id} = item;

              if (item.type === 'exclude') {
                excludeList.push({
                  label: name,
                  value: id
                });
              } else {
                includeList.push({
                  label: name,
                  value: id
                });
              }
            });

            commit('SET_SOURCELIST', {exclude: excludeList, include: includeList, data: data});
            resolve({exclude: excludeList, include: includeList, data: data});
          } else {
            Message.error(error);
            reject(error);
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
    materialList({ commit }) {
      return new Promise((resolve, reject) => {
        materialList().then(response => {
          const {state, data, error} = response.data;

          if (state) {
            const materials = _.orderBy(data, ['created'], ['desc']);

            commit('SET_MATERIALS', materials);
            resolve(materials);
          } else {
            Message.error(error);
            reject(error);
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
  }
}

export default user
