import { medias } from "@/utils/constants";
import { province } from '@/utils/constants';

const config = {
  state: {
    medias: medias,
    menus_dict: {overview: 1, repository: 2, yq: 3, compare: 4, analysis: 5, brief: 6, autobrief: 7, warning: 8, search: 9,military: 10, source: 11},
    path_dict: {index: "overview", repository: "repository", list: "yq", compare: "compare", analysis: "analysis", brief: "brief", autobrief: "autobrief", search: 'search', military: "military", source: "source"},
    province: province,
    reset_token: null,
    yqFilter: {plan_id: null, span: null}, // 舆情信息切换保存选中信息
    sourceFilter: {plan_id: null, span: null}, // 信源监测切换保存选中方案
    reportPollFlag: null, // 轮询简报状态标记
    analysisPollFlag: null, // 轮询舆情分析状态标记
    comparePollFlag: null, // 轮询舆情比较状态标记
  },
  mutations: {
    SET_YQ_FILTER(state, params) {
      state.yqFilter[params.key] = params.value;
    },
    SET_SOURCE_FILTER(state, params) {
      state.sourceFilter[params.key] = params.value;
    },
    SET_RESET_TOKEN(state, token) {
      state.reset_token = token;
    },
    SET_REPORT_POLL_FLAG(state, flag = null) {
      state.reportPollFlag = flag
    },
    SET_ANALYSIS_POLL_FLAG(state, flag = null) {
      state.analysisPollFlag = flag
    },
    SET_COMPARE_POLL_FLAG(state, flag = null) {
      state.comparePollFlag = flag
    }
  },
  actions: {
  }
}

export default config
