const getters = {
  user: state => state.user.user,
  bundle: state => state.user.bundle,
  materials: state => state.user.materials,
  power_mode: state => state.user.user.power_mode,
  plans: state => state.user.plans,
  menus: state => state.user.menus,
  hasBriefMode: state => state.user.hasBriefMode,
  sourceExcludeList: state => state.user.sourceList.exclude,
  sourceIncludeList: state => state.user.sourceList.include,
  orientationSourceList: state => state.user.sourceList.data,
  menus_dict: state => state.config.menus_dict,
  path_dict: state => state.config.path_dict,
  medias: state => state.config.medias,
  province: state => state.config.province,
  yqFilter: state => state.config.yqFilter,
  reportPollFlag: state => state.config.reportPollFlag,
  analysisPollFlag: state => state.config.analysisPollFlag,
  comparePollFlag: state => state.config.comparePollFlag
}
export default getters
